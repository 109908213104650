import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withContext from 'store/withContext'
import { Link, navigate } from 'gatsby'

import { ComponentWrapper } from './Menu.css'

class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // darkTheme: false,
      showMobileNav: false,
      hideSpoilers: false,
      hideTwitch: false,
    }
  }

  // TODO: revisit, cleanup, and comment
  dynamicNav = () => {
    let links = document.querySelector('.c-Menu .links').getBoundingClientRect()
    let utility = document
      .querySelector('.c-Menu .utility-wrapper')
      .getBoundingClientRect()
    let overlap = !(
      links.right < utility.left ||
      links.left > utility.right ||
      links.bottom < utility.top ||
      links.top > utility.bottom
    )
    let overflowDom = document.querySelector('.c-Menu .links .overflow')
    if (overlap) {
      let length = document.querySelector('.c-Menu .links').childNodes.length
      let moreDom = document.querySelector('.c-Menu .links .overflow .children')
      let toMoveItem = document.querySelector('.c-Menu .links').childNodes[
        length - 4
      ]
      let moreLength = document.querySelector(
        '.c-Menu .links .overflow .children'
      ).childNodes.length
      if (toMoveItem) {
        moreDom.prepend(toMoveItem)
      }
    } else {
      let length = document.querySelector('.c-Menu .links .overflow .children')
        .childNodes.length
      let toMoveItem = document.querySelector(
        '.c-Menu .links .overflow .children'
      ).childNodes[0]
      let linksDom = document.querySelector('.c-Menu .links')
      if (length >= 6 && toMoveItem) {
        if (utility.left - links.right > toMoveItem.offsetWidth - 100) {
          linksDom.insertBefore(toMoveItem, linksDom.lastChild)
        }
      }
    }
  }

  toggleDarkTheme = e => {
    if (e.target.checked === false) {
      document.body.classList.remove('dark')
      // this.setState({darkTheme: false});
      this.props.context.actions.setDarkTheme(false)
      localStorage.setItem("hrzTheme", "light");
    }
    if (e.target.checked) {
      document.body.classList.add('dark')
      // this.setState({darkTheme: true});
      this.props.context.actions.setDarkTheme(true)
      localStorage.setItem("hrzTheme", "dark");
    }
  }

  toggleSpoilers = e => {
    if (e.target.checked === false) {
      document.body.classList.remove('spoiler')
      //this.setState({hideSpoilers: true});

      this.props.context.actions.setSpoilers(false)
      localStorage.setItem("hrzSpoiler", "false");
    }
    if (e.target.checked) {
      document.body.classList.add('spoiler')
      //this.setState({hideSpoilers: true});
      this.props.context.actions.setSpoilers(true)
      localStorage.setItem("hrzSpoiler", "spoiler");
    }
  }

  createCookie = (name, value, days) => {
    let expires
    if (days) {
      let date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toGMTString()
    } else {
      expires = ''
    }
    document.cookie = name + '=' + value + expires + ';path=/'
  }

  // read cookie
  readCookie = name => {
    if (typeof window !== 'undefined') {
      let nameEQ = name + '='
      let ca = document.cookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length)
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length)
        }
      }
      return null
    }
  }

  toggleMenu = () => {
    if (this.state.showMobileNav) {
      this.setState({ showMobileNav: false })
    } else {
      this.setState({ showMobileNav: true })
    }
  }

  goToLink = (path, dest) => {
    path === '' ? navigate('/') : navigate(`/${path}/${dest ? dest : ''}`)
  }

  closeMenu = e => {
    if (e.target.id === 'menu-container') {
      this.setState({ showMobileNav: false })
    }
  }
  /*generateTeamDrop = (location, team, i) => {
    console.log(
      process.env.GATSBY_TEAM_REGION_WHITELIST,
      team.region,
      team.region === process.env.GATSBY_TEAM_REGION_WHITELIST
    )
    if (
      process.env.GATSBY_TEAM_REGION_WHITELIST == null ||
      team.region == process.env.GATSBY_TEAM_REGION_WHITELIST
    ) {
      if (location == 'not_mobile') {
        return (
          <Link
            className="link"
            to={`/teams/${team.tag.toLowerCase()}`}
            key={team + i + 'link'}
          >
            {team.name}
          </Link>
        )
      } else if (location == 'mobile') {
        return (
          <Link
            className="link"
            to={`/teams/${team.tag.toLowerCase()}`}
            key={team + i + 'link'}
          >
            <img src={team.logo} />
            <p>{team.name}</p>
          </Link>
        )
      }
    }
  }*/
  componentWillMount() {
    if (typeof window !== `undefined` && typeof document !== `undefined`) {
      let hrzTheme=localStorage.getItem("hrzTheme")
      if (
        hrzTheme &&
        hrzTheme === 'dark' || !hrzTheme
      ) {
        document.body.classList.add('dark')
        // this.setState({darkTheme: true});
        this.props.context.actions.setDarkTheme(true)
        localStorage.setItem("hrzTheme", "dark");
      }
      else if (hrzTheme === 'light'){
        this.props.context.actions.setDarkTheme(false)
      }
      let hrzSpoiler=localStorage.getItem("hrzSpoiler");
      if (
        hrzSpoiler &&
        hrzSpoiler === 'spoiler'
      ) {
        document.body.classList.add('spoiler')
        //this.setState({hideSpoilers: true});
        this.props.context.actions.setSpoilers(true)
        localStorage.setItem("hrzSpoiler", "spoiler");
      }
    }
  }

  componentDidMount() {
    if (typeof window !== `undefined` && typeof document !== `undefined`) {
      this.dynamicNav()
      window.addEventListener('resize', this.dynamicNav)
      window.addEventListener('onload', this.dynamicNav)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ showMobileNav: false })
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.dynamicNav)
    window.removeEventListener('onload', this.dynamicNav)
  }

  render() {
    const { teams } = this.props.context
    return (
      <ComponentWrapper className="c-Menu">
        <div className="desktop">
          <div className="branding">
            <Link to="/">
              
              {process.env.GATSBY_LEAGUE == 'SPL' ? (
              <img src="https://webcdn.hirezstudios.com/smite-esports/assets/smite-2-esports.png"
              className={`icon-${process.env.GATSBY_LEAGUE.toLowerCase()}`}
            />
            ) : (
              <i
                className={`icon-${process.env.GATSBY_LEAGUE.toLowerCase()}`}
              />
            )}
            </Link>
          </div>
          <div className="links">
            <Link to="/news/">News</Link>
            <div className="more overflow" to="/more">
              Founder's Series
              <div className="children">
                <a
                  className="link"
                  href="https://www.smiteesports.com/news/vegas-format-overview"
                >
                  Founder's Series Overview
                </a>
                <a
                  className="link"
                  href="https://www.smiteesports.com/vegas"
                >
                  Ticket Information
                </a>
              </div>
            </div>
            <Link to="/creatorcup">Creator Cup</Link>
            {process.env.GATSBY_LEAGUE == 'SCC' && 
              <Link to="/league-scc">League</Link>
            }
            <a
              target="_blank"
              rel="noreferrer"
              href="https://shop.hirezstudios.com/"
            >
              Merch
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://youtube.com/smiteVOD"
            >
              VODs
            </a>
            <div className="more overflow" to="/more">
              SMITE 1

              <div className="children">
                
                <Link to="/schedule">Schedule</Link>
                <Link to="/scores">Scores</Link>
                <Link to="/standings">Standings</Link>
                <Link to="/stats">Stats</Link>
                <Link to="/teams/">Teams</Link>
              </div>
            </div>
            {process.env.GATSBY_LEAGUE == 'SCC' && (
              <a target="_blank" href="https://www.smiteproleague.com/">
                SPL
              </a>
            )}
            <div className="more overflow" to="/more">
              More
              <div className="children">
                <a target="_blank" href="https://scc.smiteproleague.com/">
                  SMITE Challenger Circuit
                </a>
                <a
                  className="link"
                  href="https://scc.smiteproleague.com/news/smite-open-circuit-2022-participation-guide"
                >
                  SMITE Open Circuit
                </a>
                <a className="link" href="/news/smite-community-tournaments">
                  Community Tournaments
                </a>
              </div>
            </div>
          </div>
          <div className="utility-wrapper">
            <span className="darkModeSwitch light">Light</span>
            <label className="switch" htmlFor="checkbox">
              <input
                type="checkbox"
                id="checkbox"
                checked={this.props.context.darkTheme}
                onChange={e => this.toggleDarkTheme(e)}
              />
              <div className="slider round"></div>
            </label>
            <span className="darkModeSwitch">Dark</span>

            <label className="switch-spoiler" htmlFor="checkbox-spoiler">
              <input
                type="checkbox"
                id="checkbox-spoiler"
                checked={this.props.context.hideSpoilers}
                onChange={e => this.toggleSpoilers(e)}
              />
              <div className="slider round spoiler-wrapper">
                {this.props.context.hideSpoilers
                  ? 'SHOW SPOILERS'
                  : 'HIDE SPOILERS'}{' '}
                <div className="icon exclamation-alert"></div>
              </div>
            </label>
            {/* <div className="services">
              <div className="games"><img src="https://webcdn.hirezstudios.com/smite-esports/assets/games-icon.png" alt=""/></div>
              <div className="account"><img src="https://webcdn.hirezstudios.com/smite-esports/assets/account-icon.png" alt=""/></div>
            </div> */}
            <div className="play">
              <a href="https://www.smitegame.com/play-for-free">Play SMITE</a>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="menu-header">
            <div className="icon-housing" onClick={() => this.toggleMenu()}>
              <div className="menu-icon"></div>
            </div>
            <div className="branding" onClick={() => this.goToLink('')}>
              <i
                style={{ color: '#FFF', margin: '0' }}
                className={`icon-${process.env.GATSBY_LEAGUE.toLowerCase()}`}
              />
            </div>
            <div></div>
          </div>
          <div
            className={`menu-container ${
              this.state.showMobileNav ? 'is-visible' : ''
            }`}
            id="menu-container"
            onClick={e => this.closeMenu(e)}
          >
            <div className="search">
              <input type="text" placeholder="Search..." />
            </div>
            <div className="sidebar">
              <div className="links">
                <Link className="link" to="/">Home</Link>
                
                <Link className="link" to="/vegas"></Link>
                <div className="link more">
                  <div className="link-container">
                    <p>Founder's Series</p>
                    <div className="arrow" />
                  </div>
                  <div className="more-container ">
                    <a
                      className="link"
                      href="https://www.smiteesports.com/news/vegas-format-overview"
                    >
                      Founder's Series Overview
                    </a>
                    <a
                      className="link"
                      href="/vegas"
                    >
                      Ticket Information
                    </a>
                  </div>
                </div>
                <Link className="link" to="/creatorcup">Creator Cup</Link>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                  href="https://shop.hirezstudios.com/"
                >
                  Merch
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                  href="https://youtube.com/smiteVOD/"
                >
                  VODS
                </a>
                <Link className="link" to="/league">
                  League
                </Link>
                <Link className="link" to="/news/">
                  News
                </Link>
                
                <div className="link more">
                  <div className="link-container">
                    <p>SMITE 1</p>
                    <div className="arrow" />
                  </div>
                  <div className="more-container ">
                    
                    <Link className="link" to="/schedule/">Schedule</Link>
                    <Link className="link" to="/scores/">Scores</Link>
                    <Link className="link" to="/standings/">Standings</Link>
                    <Link className="link" to="/stats/">Stats</Link>
                  </div>
                </div>

                <div className="link more">
                  <div className="link-container">
                    <p>More</p>
                    <div className="arrow" />
                  </div>
                  <div className="more-container ">
                    {process.env.GATSBY_LEAGUE == 'SPL' ? (
                      <a
                        className="link"
                        href="https://scc.smiteproleague.com/"
                      >
                        SMITE Challenger Circuit
                      </a>
                    ) : (
                      <a className="link" href="https://smiteproleague.com/">
                        SMITE Pro League
                      </a>
                    )}

                    <a
                      className="link"
                      href="https://play.smiteproleague.com/open-circuit"
                    >
                      SMITE Open Circuit
                    </a>
                    <a
                      className="link"
                      href="/news/smite-community-tournaments"
                    >
                      Community Tournaments
                    </a>
                  </div>
                </div>
              </div>
              <div className="darkModeSwitchWrapper">
                <span className="darkModeSwitch light">Light</span>
                <label className="switch" htmlFor="checkbox">
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={this.props.context.darkTheme}
                    onChange={e => this.toggleDarkTheme(e)}
                  />
                  <div className="slider round"></div>
                </label>
                <span className="darkModeSwitch">Dark</span>
              </div>

              <div className="mobile-spoiler">
                <label className="switch-spoiler" htmlFor="checkbox-spoiler">
                  <input
                    type="checkbox"
                    id="checkbox-spoiler"
                    checked={this.props.context.hideSpoilers}
                    onChange={e => this.toggleSpoilers(e)}
                  />
                  <div className="slider round spoiler-wrapper">
                    {this.props.context.hideSpoilers
                      ? 'SHOW SPOILERS'
                      : 'HIDE SPOILERS'}{' '}
                    <div className="icon exclamation-alert"></div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </ComponentWrapper>
    )
  }
}

Menu.propTypes = {
  teams: PropTypes.array,
}

export default withContext(Menu)
